<template>
  <sm-editable-list
    :key="updateList"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :quickFilterFieldsNumber="4"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    :checkedItems="checkedTasks"
    showCreateButton
    showEditButton
    showDeleteButton
    multiselect
    :showNextpageQuestion="isShowNextpageQuestion"
    @check-items="onCheckItems"
    @change-page="onChangePage"
    @create="onCreate('TelemarketingTaskCreate')"
    @edit="(id) => onEdit('TelemarketingTaskEdit', id)"
  >
    <template #list-top-panet>
      <div class="telemaketing-top-items">
        <sm-button
          class="telemaketing-top-items__button"
          :disabled="isOpenModalButtonDisabled"
          @click="onOpenModal"
          >Сменить исполнителя</sm-button
        >
      </div>

      <sm-form-modal
        v-model="modalForm"
        :fields="modalFields"
        modal-title="Заменить исполнителя"
        :show="showModalForm"
        :isLoadingSaveButton="isLoadingChangePerformer"
        :disabledSaveButton="!modalForm.newPerformerId"
        @close="onCloseModal"
        @save="onChangePerformer"
        @cancel="onCloseModal"
      />
    </template>
  </sm-editable-list>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import SmFormModal from '@/components/common/modals/SmFormModal.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'TelemarketingTasks',

  components: {
    SmEditableList,
    SmFormModal,
    SmButton,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'TelemarketingTasks',
      tableCaption: 'Задания по телемаркетингу',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Задания по телемаркетингу',
        },
      ],
      checkedTasks: [],
      checkedTasksIds: [],
      showModalForm: false,
      modalForm: {
        newPerformerId: null,
      },
      isLoadingChangePerformer: false,
      updateList: 0,
    };
  },

  computed: {
    ...mapState({
      employeeAccounts: (state) => state.common.employeeAccounts,
    }),

    modalFields() {
      return [
        {
          type: 'select',
          key: 'newPerformerId',
          label: 'Новый исполнитель',
          list: this.employeeAccounts.data,
        },
      ];
    },

    hidePersonalData() {
      const hidePersonalData = localStorage.getItem('hidePersonalData');
      return hidePersonalData === 'true' ? true : false;
    },

    tableHeaders() {
      const headers = [
        { text: '№ задания', alias: 'id', order: 'id' },
        { text: 'Исполнитель', alias: 'fio', order: 'fio' },
        { text: 'Продукт телемаркетинга', alias: 'product', order: 'product' },
        { text: 'Скрипт телемаркетинга', alias: 'script', order: 'script' },
        { text: 'Теги', alias: 'tags', order: 'tags' },
        { text: 'Комментарий', alias: 'comment', order: 'comment' },
        { text: 'Результат обзвона', alias: 'callResult', order: 'callResult' },
        {
          text: 'Название организации',
          alias: 'organizationName',
          order: 'organizationName',
        },
        {
          text: 'Адрес организации',
          alias: 'organizationAddress',
          order: 'organizationAddress',
        },
        // {
        //   text: 'Телефон организации',
        //   alias: 'organizationPhone',
        //   order: 'organizationPhone',
        // },
        {
          text: 'Email организации',
          alias: 'organizationMail',
          order: 'organizationMail',
        },
        {
          text: 'Веб-сайт организации',
          alias: 'organizationUrl',
          order: 'organizationUrl',
        },
        {
          text: 'ИНН организации',
          alias: 'organizationInn',
          order: 'organizationInn',
        },

        {
          text: 'ФИО контактного лица',
          alias: 'contactFio',
          order: 'contactFio',
        },
        {
          text: 'ФИО директора',
          alias: 'chief',
          order: 'chief',
        },
        {
          text: 'Мобильный телефон контактного лица',
          alias: 'contactPhoneMobile',
          order: 'contactPhoneMobile',
        },
        {
          text: 'Рабочий телефон контактного лица',
          alias: 'contactPhoneWork',
          order: 'contactPhoneWork',
        },
        {
          text: 'Email контактного лица',
          alias: 'contactMail',
          order: 'contactMail',
        },
        {
          text: 'Должность контактного лица',
          alias: 'contactPosition',
          order: 'contactPosition',
        },
        {
          text: 'Дата создания',
          alias: 'created',
          order: 'created',
        },
        {
          text: 'Дата выполнения задания',
          alias: 'completionDate',
          order: 'completionDate',
        },
        { alias: 'actions' },
      ];
      const additionalheder = {
        text: 'Телефон организации',
        alias: 'organizationPhone',
        order: 'organizationPhone',
      };

      if (!this.hidePersonalData) {
        headers.splice(7, 0, additionalheder);
      }
      return headers;
    },

    isOpenModalButtonDisabled() {
      return !this.checkedTasks.length;
    },

    isShowNextpageQuestion() {
      return this.checkedTasks.length > 0;
    },
  },

  created() {
    this.isLoadingPage = true;

    this.getCommonList({ name: 'EmployeeAccounts' }).then(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
      changePerformer: 'telemarketing/changePerformer',
    }),

    onCheckItems(items) {
      this.checkedTasks = items;
      this.checkedTasksIds = items.map((item) => item.id);
    },

    onChangePage() {
      this.checkedTasks = [];
      this.checkedTasksIds = [];
    },

    onOpenModal() {
      this.showModalForm = true;
    },

    onChangePerformer() {
      this.isLoadingChangePerformer = true;

      this.changePerformer({
        newPerformerId: this.modalForm.newPerformerId,
        tasksIds: this.checkedTasksIds,
      })
        .then((response) => {
          if (response?.isSucceed) {
            this.checkedTasks = [];
            this.checkedTasksIds = [];
            this.updateList++;
          } else throw new Error(response?.error);
        })
        .catch((error) => {
          const message =
            !error?.message || error.message === 'Network Error'
              ? 'Доступ запрещен'
              : error.message;

          this.$notify({
            header: message,
            type: 'error',
            timer: 5000,
          });
        })
        .finally(() => {
          this.isLoadingChangePerformer = false;
          this.showModalForm = false;

          this.clearModal();
        });
    },

    clearModal() {
      this.tasksIds = [];
      this.modalForm.newPerformerId = null;
    },

    onCloseModal() {
      this.showModalForm = false;
      this.clearModal();
    },
  },
};
</script>
<style lang="scss">
.telemaketing-top-items {
  display: flex;
  justify-content: flex-end;
}

.telemaketing-top-items__button {
  width: auto;
}
</style>

<template>
  <div class="input-with-select">
    <label v-if="label" class="input-with-select__label">{{ label }}</label>
    <div class="input-with-select__inner-wrapper">
      <sm-select
        v-model="value.operation"
        :options="operations"
        class="input-with-select__select"
        @select="onSelect"
      />
      <template v-if="isBetween">
        <sm-input
          v-model="value.valueFrom"
          class="input-with-select__range-input"
          :type="type"
        />
        <sm-input
          v-model="value.valueTo"
          class="input-with-select__range-input"
          :type="type"
        />
      </template>
      <template v-else>
        <sm-input
          v-model="value.value"
          class="input-with-select__input"
          :type="type"
          @input="onInput"
        />
      </template>
    </div>
  </div>
</template>

<script>
import SmSelect from '@/components/common/forms/SmSelect.vue';
import SmInput from '@/components/common/forms/SmInput.vue';

import filterTypes from '@/constants/filterTypes.js';

export default {
  name: 'SmInputWithSelect',

  components: {
    SmSelect,
    SmInput,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },

    label: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    filterType: {
      type: String,
      default: 'String',
    },
  },

  computed: {
    operations() {
      return filterTypes[this.filterType];
    },

    isBetween() {
      return this.value.operation === 'Between';
    },
  },

  methods: {
    onSelect(option) {
      if (option.value === 'Between') {
        delete this.value.value;
      } else {
        delete this.value.valueFrom;
        delete this.value.valueTo;
      }
    },

    onInput() {
      const { value, operation } = this.value;
      if (value && operation) {
        return;
      }

      if (value && !operation) {
        this.value.operation =
          this.filterType === 'String' ? 'Contain' : 'Equal';
        return;
      }

      if (!value && operation !== 'Between') {
        this.value.operation = '';
      }
    },
  },
};
</script>

<style lang="scss">
.input-with-select {
  width: 100%;
}

.input-with-select__label {
  display: inline-block;
  margin-bottom: 3px;

  font-size: 16px;
  line-height: 1;
  color: var(--gray);
}

.input-with-select__inner-wrapper {
  display: flex;
}

.input-with-select__select {
  width: 30%;
  margin-right: 10px;
}

.input-with-select__range-input {
  margin-right: 10px;
  width: calc(35% - 5px);

  &:last-of-type {
    margin-right: 0;
  }
}

.input-with-select__input {
  width: 70%;
}
</style>

<template>
  <div class="datepicker-with-select">
    <label v-if="label" class="datepicker-with-select__label">{{
      label
    }}</label>
    <div class="datepicker-with-select__inner-wrapper">
      <sm-select
        v-model="value.operation"
        :options="operations"
        class="datepicker-with-select__select"
        @select="onSelect"
      />
      <template v-if="isBetween">
        <sm-datepicker
          v-model="value.valueFrom"
          :type="type"
          :format="format"
          :valueType="valueType"
          :timeTitleFormat="timeTitleFormat"
          class="datepicker-with-select__range-datepicker"
        />
        <sm-datepicker
          v-model="value.valueTo"
          :type="type"
          :format="format"
          :valueType="valueType"
          :timeTitleFormat="timeTitleFormat"
          class="datepicker-with-select__range-datepicker"
        />
      </template>
      <template v-else>
        <sm-datepicker
          v-model="value.value"
          :type="type"
          :format="format"
          :valueType="valueType"
          :timeTitleFormat="timeTitleFormat"
          class="datepicker-with-select__datepicker"
          @input="onInput"
        />
      </template>
    </div>
  </div>
</template>

<script>
import SmSelect from '@/components/common/forms/SmSelect.vue';
import SmDatepicker from '@/components/common/forms/SmDatepicker.vue';

import filterTypes from '@/constants/filterTypes.js';

export default {
  name: 'SmDatepickerWithSelect',

  components: {
    SmSelect,
    SmDatepicker,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },

    label: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'date',
    },

    filterType: {
      type: String,
      default: 'String',
    },

    format: {
      type: String,
      default: 'DD.MM.YYYY',
    },

    valueType: {
      type: String,
      default: 'DD.MM.YYYY',
    },

    timeTitleFormat: {
      type: String,
      default: 'DD.MM.YYYY',
    },
  },

  computed: {
    operations() {
      return filterTypes[this.filterType];
    },

    isBetween() {
      return this.value.operation === 'Between';
    },
  },

  methods: {
    onSelect(option) {
      if (option.value === 'Between') {
        delete this.value.value;
      } else {
        delete this.value.valueFrom;
        delete this.value.valueTo;
      }
    },

    onInput() {
      const { value, operation } = this.value;
      if (value && operation) {
        return;
      }

      if (value && !operation) {
        this.value.operation =
          this.filterType === 'String' ? 'Contain' : 'Equal';
        return;
      }

      if (!value && operation !== 'Between') {
        this.value.operation = '';
      }
    },
  },
};
</script>

<style lang="scss">
.datepicker-with-select {
  width: 100%;
}

.datepicker-with-select__label {
  display: inline-block;
  margin-bottom: 3px;

  font-size: 16px;
  line-height: 1;
  color: var(--gray);
}

.datepicker-with-select__inner-wrapper {
  display: flex;
}

.datepicker-with-select__select {
  width: 30%;
  margin-right: 10px;
}

.datepicker-with-select__range-datepicker {
  margin-right: 10px;
  width: calc(35% - 5px);

  &:last-of-type {
    margin-right: 0;
  }
}

.datepicker-with-select__datepicker {
  width: 70%;
}
</style>

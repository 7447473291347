const numberType = [
  { value: '', name: 'Все' },
  { value: 'Equal', name: '=' },
  { value: 'NotEqual', name: '≠' },
  { value: 'Less', name: '<' },
  { value: 'LessOrEqual', name: '≤' },
  { value: 'More', name: '>' },
  { value: 'MoreOrEqual', name: '≥' },
  { value: 'Between', name: 'Между' },
];

const stringType = [
  { value: '', name: 'Все' },
  { value: 'Contain', name: 'Содержит' },
  { value: 'Equal', name: '=' },
  { value: 'NotEqual', name: '≠' },
];

const boolType = [
  { value: '', name: 'Все' },
  { value: 'Equal', name: '=' },
  { value: 'NotEqual', name: '≠' },
];

export default {
  String: stringType,
  Int: numberType,
  Decimal: numberType,
  Date: numberType,
  DateTime: numberType,
  Bool: boolType,
};

<template>
  <label class="switch__container">
    <span v-if="isLabelLeft" class="switch__label checkbox__label--left">{{
      label
    }}</span>
    <input
      :value="value"
      :checked="shouldBeChecked"
      :disabled="disabled"
      type="checkbox"
      class="switch"
      @change="onChange"
    />
    <span v-if="isLabelRight" class="switch__label checkbox__label--right">{{
      label
    }}</span>
  </label>
</template>

<script>
export default {
  name: 'SmSwitch',

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    label: {
      type: String,
      default: '',
    },

    labelPosition: {
      type: String,
      default: 'right',
    },

    value: {
      type: [Boolean, Number, String, undefined, null],
    },

    modelValue: {
      default: false,
    },

    trueValue: {
      default: true,
    },

    falseValue: {
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === this.trueValue;
    },

    isLabelLeft() {
      return this.labelPosition === 'left';
    },

    isLabelRight() {
      return !this.isLabelLeft;
    },
  },

  methods: {
    onChange(event) {
      let isChecked = event.target.checked;

      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];

        isChecked
          ? newValue.push(this.value)
          : newValue.splice(newValue.indexOf(this.value), 1);

        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss">
.switch {
  position: relative;
  width: 29px;
  height: 15px;
  z-index: 0;
  appearance: none;
  -webkit-appearance: none;
  background-color: var(--gray);
  outline: none;
  border-radius: 40px;
  box-shadow: inset 0 0 5px rgba(var(--rgb-black), 0.2);
  transition: 0.3s;
  cursor: pointer;

  &:checked {
    background-color: var(--blue);
  }

  &::before {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: 1px;
    left: 1px;
    background-color: var(--white);
    transition: scale(1.1);
    box-shadow: 0 2px 5px rgba(var(--rgb-black), 0.2);
    transition: 0.3s;
    z-index: 1;
  }

  &:checked::before {
    left: 15px;
  }

  &:disabled {
    cursor: default;
  }
}

.switch__container {
  display: flex;
  align-items: center;
}

.switch__label {
  margin: 0;
  font-weight: 400px;
  color: var(--gray);
}

.switch__label--left {
  padding-right: 5px;
}

.switch__label--right {
  padding-left: 5px;
}
</style>

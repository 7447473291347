<template>
  <div class="select-with-input">
    <label v-if="label" class="select-with-input__label">{{ label }}</label>
    <div class="select-with-input__select">
      <sm-select
        ref="select"
        :value.sync="selectedValues"
        :search="!field.catalogIsLarge"
        :options="options"
        :multiselect="isMultiple"
        :clearable="clearable"
        @select="onSelect"
        @selectAll="onSelectAll"
      >
        <template #options-header>
          <sm-input
            v-show="field.catalogIsLarge"
            ref="selectSearch"
            v-model="itemsFilter"
            class="select-search"
            placeholder="Поиск"
          />
          <small v-show="field.catalogIsLarge && hasMoreItems">
            *список слишком большой, введите название.
          </small>
          <small v-show="field.subtitle"> *{{ field.subtitle }} </small>
        </template>
      </sm-select>
      <sm-switch
        v-if="multiple"
        class="select-with-input__switch"
        label="Множественный выбор"
        v-model="isMultiple"
      />
    </div>
  </div>
</template>

<script>
// components
import smSelect from '/src/components/common/forms/SmSelect.vue';
import smInput from '/src/components/common/forms/SmInput.vue';
import smSwitch from '/src/components/common/forms/SmSwitch.vue';

export default {
  name: 'SmSelectWithSearch',

  components: {
    smSelect,
    smInput,
    smSwitch,
  },

  model: {
    prop: 'value',
    event: 'update:value',
  },

  props: {
    value: {
      type: [String, Number, Boolean, Array],
    },

    label: {
      type: String,
      default: '',
    },

    options: {
      type: Array,
      default: () => [],
    },

    field: {
      type: Object,
      default: () => {},
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      itemsFilter: '',
      selectedValues: null,
      isMultiple: false,
    };
  },

  watch: {
    itemsFilter() {
      this.fetchItems();
    },

    value: {
      handler() {
        this.selectedValues = this.value;
        if (this.value === '') this.itemsFilter = '';
      },
      deep: true,
      immediate: true,
    },

    selectedValues: {
      handler(val) {
        if (val === '') {
          this.$emit('update:value', '');
          this.$emit('clearChildList', this.field);
        }
        if (Array.isArray(val)) {
          this.isMultiple = true;
        }
      },
      immediate: true,
    },

    isMultiple() {
      this.$refs.select.selectedOptions = [];
      this.$emit('updateFilter', this.field);
    },
  },

  mounted() {
    this.selectActions();

    this.$watch(
      () => {
        return this.$refs.select.isOpen;
      },
      (val) => {
        if (val === true) {
          const selectSearch =
            this.$refs.selectSearch.$el.querySelector('.input');
          this.$nextTick(() => {
            selectSearch.focus();
          });
        }
      }
    );
  },

  computed: {
    hasMoreItems() {
      if (!this.field.catalogName) return;
      return this.$store.state.filter[this.field.catalogName]?.data?.hasMore;
    },

    processedOptions() {
      return this.options.map((item) => {
        if (this.value.includes(item.value)) {
          return {
            ...item,
            selected: true,
          };
        }
        return {
          ...item,
          selected: false,
        };
      });
    },

    isSelectedAll() {
      // Условие больше или равно нужно для больших списков, где при каждом поиске посылается запрос и список обновляется
      return (
        this.isMultiple &&
        this.selectedValues.length >= this.processedOptions.length &&
        this.processedOptions.every((option) => option.selected)
      );
    },
  },

  methods: {
    selectActions() {
      const select = this.$refs.select;
      if (!select) return;

      const selectSearch = this.$refs.selectSearch.$el.querySelector('.input');

      this.$nextTick(() => {
        selectSearch.focus();
      });
    },

    fetchItems() {
      const requestData = {
        field: this.field,
        params: {
          filter: this.itemsFilter,
          limit: 10,
        },
      };
      this.$emit('fetchItems', requestData);
    },

    onSelect() {
      this.$emit('update:value', this.selectedValues);
    },

    onSelectAll() {
      if (!this.options) return;

      if (this.isSelectedAll) {
        const options = this.value.filter(
          (item) =>
            !this.processedOptions.some((option) => option.value === item)
        );

        this.$emit('update:value', options);
        return;
      } else {
        this.processedOptions.forEach((item) => {
          item.selected = true;
          if (
            Array.isArray(this.selectedValues) &&
            !this.selectedValues.some((value) => value === item.value)
          ) {
            this.selectedValues.push(item.value);
          } else {
            this.selectedValues = this.processedOptions
              .filter((option) => option.selected)
              .map((option) => option.value);
          }
        });
        this.$emit('update:value', this.selectedValues);
      }
    },
  },
};
</script>

<style lang="scss">
.select-with-input {
  width: 100%;
}

.select-with-input__label {
  display: inline-block;
  margin-bottom: 3px;

  font-size: 16px;
  line-height: 1;
  color: var(--gray);
}

.select-with-input__select {
  margin-bottom: 16px;
}

.select-with-input__switch {
  margin-top: 5px;
}
</style>
